import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  class: "py-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!
  const _component_ArchiveDescription = _resolveComponent("ArchiveDescription")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('manuscripts.overview')
    }, null, 8, ["title"]),
    _createVNode(_component_RemoteContent, { data: _ctx.manuscriptWeb }, {
      default: _withCtx(() => [
        (_ctx.isLoaded(_ctx.manuscriptWeb))
          ? (_openBlock(), _createBlock(_component_ArticleContainer, {
              key: 0,
              class: "my-6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.manuscriptWeb.payload.introduction), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('manuscripts.by_archive')), 1),
    _createVNode(_component_RemoteContent, { data: _ctx.archives }, {
      default: _withCtx(() => [
        (_ctx.isLoaded(_ctx.archives))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.archives.payload, (a) => {
                return (_openBlock(), _createElementBlock("a", {
                  id: "archive_{{ a.id }}",
                  key: a.id
                }))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.archives.payload, (a) => {
                return (_openBlock(), _createBlock(_component_ArchiveDescription, {
                  key: a.name,
                  archive: a
                }, null, 8, ["archive"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}