import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "showLinks"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "pt-6 showLinks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageHolder = _resolveComponent("ImageHolder")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createBlock(_component_ArticleContainer, {
    class: "py-3 my-3",
    id: `archive_${_ctx.archive.id}`
  }, {
    default: _withCtx(() => [
      (_ctx.archive.link)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_1, [
            _createElementVNode("a", {
              href: _ctx.archive.link,
              target: "_blank"
            }, _toDisplayString(_ctx.archiveName), 9, _hoisted_2)
          ]))
        : (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.archiveName), 1)),
      _createElementVNode("div", null, [
        (_ctx.archive.image)
          ? (_openBlock(), _createBlock(_component_ImageHolder, {
              key: 0,
              class: "h-52 float-left pr-4 pt-2 pb-4",
              imageUrl: _ctx.publicPath + 'img/' + _ctx.archive.image,
              imageCaption: _ctx.archive.image_description,
              imageCaptionLink: _ctx.archive.image_link
            }, null, 8, ["imageUrl", "imageCaption", "imageCaptionLink"]))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: "min-h-52 showLinks",
          innerHTML: _ctx.archive.description
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('manuscripts.list_of_manuscripts')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.archive.manuscripts, (m) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: m.id,
            to: _ctx.manuscriptLink(m)
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(m.title), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}