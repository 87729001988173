
import { computed, defineComponent, PropType } from 'vue';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import ImageHolder from '@/components/global/ImageHolder.vue';
import { ArchiveDetail } from '@/interfaces/ArchiveDetail';
import { RouteLocationRaw } from 'vue-router';
import { ManuscriptShort } from '@/interfaces/ManuscriptShort';

export default defineComponent({
  props: { archive: Object as PropType<ArchiveDetail> },
  components: {
    ArticleContainer,
    ImageHolder,
  },
  setup(props) {
    const publicPath = process.env.BASE_URL;
    const archiveName = computed(() => {
      if (props.archive) {
        return `${props.archive.name} (${props.archive.city}, ${props.archive.country_code})`;
      }
      return '';
    });

    function manuscriptLink(m: ManuscriptShort): RouteLocationRaw {
      const sura = m.sura ? m.sura : 1;
      const verse = m.verse > 0 ? m.verse : 1;
      let query;
      if (m.sura > 0 && m.verse > 0) {
        query = {
          sura,
          verse,
        };
      }

      if (m.page) {
        return {
          name: 'ManuscriptPage',
          params: {
            manuscript: m.id,
            page: m.page,
          },
          query,
        };
      }

      return {
        name: 'ManuscriptSingle',
        params: { manuscript: m.id },
      };
    }

    return {
      publicPath,
      archiveName,
      manuscriptLink,
    };
  },
});
